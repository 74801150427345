import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import type { IntlShape } from 'react-intl-next';
import { defineMessages, useIntl } from 'react-intl-next';
import IntlRelativeFormat from 'intl-relativeformat';

import { getTranslation } from '@confluence/i18n';

export type TimeAgoProps = {
	date: Date | string | number;
	usePrepositions?: boolean;
	dateFormatOptions?: Record<string, string>;
};

const i18n = defineMessages({
	now: {
		id: 'time-ago.time.now',
		defaultMessage: 'now',
		description:
			'Word for time that is currently happening (timestamp for events which happened < 5 seconds ago)',
	},
	lessThanAMinute: {
		id: 'time-ago.time.lessThanAMinute',
		defaultMessage: 'less than a minute ago',
		description: 'Word for time that is less than a minute ago',
	},
	onPreposition: {
		id: 'time-ago.prepositions.on',
		defaultMessage: 'on {datetime}',
		description: 'Date preposition. e.g. on January 1st 1970',
	},
});

// the lib type definition doesn't export this type directly, so we have to reference it in this clumsy way
type IntlRelativeFormatUnits = Required<
	NonNullable<ConstructorParameters<typeof IntlRelativeFormat>[1]>
>['units'];

const gradation: {
	threshold?: number;
	unit?: IntlRelativeFormatUnits;
	format?: (options: {
		intl: IntlShape;
		date: Date;
		locale: string;
		usePrepositions?: boolean;
		dateFormatOptions?: Record<string, string>;
	}) => string;
	factor?: number;
	granularity?: number;
}[] = [
	// Less than 5 seconds -> "now"
	{
		format: ({ intl }) => intl.formatMessage(i18n.now),
	},
	// Between 5-45 seconds -> "less than a minute ago"
	{
		threshold: 5,
		format: ({ intl }) => intl.formatMessage(i18n.lessThanAMinute),
	},
	// Between 45 seconds-2.5 minutes -> "X minutes ago"
	{
		threshold: 45,
		factor: 60,
		unit: 'minute',
	},
	// Between 2.5-45 minutes -> "X minutes ago" (rounded to 5)
	{
		threshold: 2.5 * 60,
		factor: 60,
		granularity: 5,
		unit: 'minute',
	},
	// Between 45 minutes-24 hours -> "X hours ago"
	{
		threshold: 45 * 60,
		factor: 60 * 60,
		unit: 'hour',
	},
	// After 24 hours -> "full date"
	{
		threshold: 24 * 60 * 60,
		format: ({ intl, date, locale, usePrepositions, dateFormatOptions }) => {
			const datetime = date.toLocaleDateString(locale, {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				...dateFormatOptions,
			});

			if (usePrepositions) {
				return intl.formatMessage(i18n.onPreposition, { datetime });
			}

			return datetime;
		},
	},
];

const getGradationIndex = (seconds: number) => {
	let i = gradation.length - 1;
	while (i > 0 && seconds < (gradation[i].threshold || seconds)) i--;
	return i;
};

const getSecondsSince = (date: Date) => (Date.now() - date.getTime()) / 1000;

export const formatTimeAgo = (
	intl: IntlShape,
	date: Date | string | number,
	usePrepositions?: boolean,
	dateFormatOptions?: Record<string, string>,
): string => {
	const locale = getTranslation().locale;

	const dateObject = new Date(date);
	const seconds = getSecondsSince(dateObject);
	const i = getGradationIndex(seconds);

	const customFormat = gradation[i].format;
	if (customFormat) {
		return customFormat({
			intl,
			date: dateObject,
			locale,
			usePrepositions,
			dateFormatOptions,
		});
	}

	const formatter = new IntlRelativeFormat(locale, {
		units: gradation[i].unit,
		style: 'numeric',
	});

	const granularity = gradation[i].granularity || 1;
	const factor = gradation[i].factor || 1;

	return formatter.format(
		new Date(
			new Date().getTime() -
				Math.round(seconds / factor / granularity) * granularity * factor * 1000,
		),
	);
};

export const StaticTimeAgo: FC<TimeAgoProps> = ({ date, usePrepositions, dateFormatOptions }) => {
	const intl = useIntl();
	return <>{formatTimeAgo(intl, date, usePrepositions, dateFormatOptions)}</>;
};

export const TimeAgo: FC<TimeAgoProps> = ({ date, ...otherProps }) => {
	const [, setRenders] = useState(0);

	const dateObject = new Date(date);
	const seconds = getSecondsSince(dateObject);
	const i = getGradationIndex(seconds);

	useEffect(() => {
		if (i != gradation.length - 1) {
			const timeToNextGradation = (gradation[i + 1].threshold || seconds) - seconds;

			const timeout = setTimeout(() => {
				setRenders((x) => x + 1);
			}, timeToNextGradation * 1000);

			return () => clearTimeout(timeout);
		}
	}, [i, seconds]);

	return <StaticTimeAgo date={date} {...otherProps} />;
};
